@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');

.offerswrap {
    max-width: 1440px;
    margin: 0 auto;
}

.spinfosec{
    padding: 20px;
    margin-top: 5%;
}

.offerr {
    background-color: whitesmoke;

}

.offersgrid {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.offerscontent {
    text-align: center;
    margin-top: 5%;
}

.bestprices {
    font-family: 'Playfair Display', serif;
}

.offerscontent span {
    font-weight: bold;
    font-family: 'Playfair Display', serif;
}

.offerscontent h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
}

.offersgrid h1 {
    text-align: center;
}

.offerscontent p {
    font-family: 'Playfair Display', serif;
    font-size: 1.2rem;
    max-width: 700px;
}

.jushi h1 {
    font-size: 4rem;
    font-family: 'Playfair Display', serif;
    text-align: center;
    color: #644222;

}
.jushi h2 {
    font-family: 'Josefin Sans', sans-serif;
    color: #644444;
    text-align: center;

}

.spinnergrid1 h2{
    font-family: 'Josefin Sans', sans-serif;
    color: #644444;
}
.spinnergrid1 li{
    font-family: 'Josefin Sans', sans-serif;


}
.spoffercards {
    padding-top: 100px;
    width: 100%;
    gap: 20px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
}

.offer {
    background-color: #fff3de;
    border: 5px dashed #564d3d;
    padding: 30px;
}

@media (max-width: 1024px) {
    .spoffercards {
        grid-template-columns: 1fr 1fr;
        /* Display 1 card per row on smaller screens */
    }
}

@media (max-width: 768px) {
    .spoffercards {
        grid-template-columns: 1fr;
        /* Display 1 card per row on smaller screens */
    }

.jushi h1{
    font-size: 2.3rem;

}

}

@media (max-width:425px) {
    .offerscontent h1 {
        font-size: 1.8rem;
    }

    .offerscontent p {
        font-size: 1rem;
    }
    
}


.offersgrid h1 {
    font-size: 2.3rem;
    font-family: 'Playfair Display', serif;
    color: #644222;
}

.offersgrid p {
    font-size: 1.4rem;
    font-family: 'Josefin Sans', sans-serif;
}

.offersgrid li {
    font-size: 1.4rem;
    font-family: 'Josefin Sans', sans-serif;
}