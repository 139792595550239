@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');

.tabscontainer {
    height: max-content;
    margin: 100px auto 0;
    word-break: break-all;
    display: grid;
    grid-template-columns: 0.55fr 1fr;
    gap: 2%;
}

.bloc-tabs {
    display: block;
}

.tabs {
    padding: 15px;
    text-align: center;
    width: 80%;
    background: rgba(236, 10, 10, 0);
    cursor: pointer;
    box-sizing: content-box;
    position: relative;
}

.active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-80%);
    width: calc(100% + 2px);
    height: 5px;
    background: beige;
    animation-name: slideIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
}

@keyframes slideIn {
    0% {
        width: 0;
    }

    100% {
        width: calc(100% + 2px);
    }
}

.bloc-tabs {
    padding: 20px;
    background-color: #422b15;
    height: max-content;
}

.bloc-tabs button {
    
    line-height: 6rem;
    color: white;
    font-size: 1.8rem;
    text-align: left;
    border: none;
}

.content-tabs {
    margin-right: 2%;
    display: flex;
 }


.content-tabs img {
    height: 600px;
  }
  
  @media (max-width: 768px) {
    .content-tabs img {
      height: auto;
      max-width: 100%;
    }
  }

.content {
    display: none;
}

.content h2 {
    padding: 0px 0 5px 0px;
}

.content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
}

.active-content {
    display: block;
}

.tabsp {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    background-color: #f2f2f2;
    padding: 20px;
    align-items: center;
    font-size: 1.2rem;
    box-shadow: 2px 2px 5px black;
    margin-bottom: 2%;
}

.tabsitem {
    padding: 20px;
}

.mainheading {
    font-size: 3.3rem;
    font-family: 'Playfair Display', serif;
    padding-left: 20px;
    color: #775D4D;
}

@media (max-width:1024px){
    .content-tabs img {
        height: 400px;
    }
    .mainheading{
        font-size: 1.4rem;
    }
    .bloc-tabs button {
        font-size: 1rem;
        line-height: 3rem;
    }
    .tabscontainer {
        grid-template-columns: 0.6fr 1fr;
        gap: 5%;

    }
}

@media (max-width:768px){
    .tabscontainer{
        grid-template-columns: 1fr;
    }
    .bloc-tabs button{
        line-height: 1rem;
        font-size: 1.4rem;
    }
    .tabsp{
        grid-template-columns:1fr;
        margin-bottom: 10%;
    }
    .mainheading {
        padding-left: 0px;
        font-size: 2rem;
    }
}
@media (max-width:425px) {
    .tabsp{
        margin-bottom: 20%;
    }
}
@media (max-width:768px){
    .bloc-tabs button{
        line-height: 1rem;
        font-size: 1rem;
    }
}

.tabshead{
    display: flex;
    flex-direction: column;
    color: #422b15;
    text-align: center;
    margin-top: 3%;
}
.tabshead h1{
    font-family: 'Playfair Display', serif;
    font-size: 3.5rem;
}
.tabshead p{
    font-weight: 900;
    font-size: 1rem;
}
.tabsbottom{
    width: 100%;
    position: relative;
    height: 2vh;
    background-color: #644222;
}