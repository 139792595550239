@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');

.formscontainer {
    max-width: 768px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 20px;
}

.formlabel {
    font-size: 9rem;
}
.formsh{
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 3.5rem;
    
}

.formsp{
    font-family: 'Playfair Display', serif;
    font-weight: 900;
    font-size: 1.2rem;
    text-align: center;
}