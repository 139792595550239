@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.afterwrap {
    max-width: 1440px;
    max-height: max-content;
    margin: 0 auto;
}

.slider {
    margin: 0 auto;
    margin-top: 10%;
    width: 400px;
    height: 300px;
    padding: 20px;
}
.afterwrap{
    margin-top:7% ;
    text-align: center;
}
@media (max-width: 768px){
    .theservices{
        font-size: 2rem;
    }
}