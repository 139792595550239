.serviceop {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.drop-down {
    list-style: none;
    overflow: hidden;
    height: 580px;
    background-color: #C69C72;
    font-family: Arial;
    width: 300px;
    margin: 10px auto;
    text-align: left;
    -webkit-transition: height 0.3s ease;
    transition: height 0.3s ease;
    border-radius: 5px;
}

.drop-down1 {
    list-style: none;
    overflow: hidden;
    height: 256px;
    background-color: #C69C72;
    font-family: Arial;
    width: 300px;
    margin: 10px auto;
    padding: 0;
    text-align: left;
    -webkit-transition: height 0.3s ease;
    transition: height 0.3s ease;
    border-radius: 5px;
}

.drop-down-no {
    list-style: none;
    background-color: #C69C72;
    font-family: Arial;
    width: 300px;
    margin: 10px auto;
    padding: 0;
    text-align: left;
    border-radius: 5px;
}

@media (max-width:400px){
    .drop-down, .drop-down1, .drop-down-no{
        width: 250px;
    }
}



.drop-down.closed {
    /*  When toggled via jQuery this class will reduce the height of the ul which inconjuction
    with overflow: hidden set on the ul will hide all list items apart from the first */
    /* current li height 38px + 5px border */
    height: 68px;

}

.drop-down1.closed {
    /*  When toggled via jQuery this class will reduce the height of the ul which inconjuction
    with overflow: hidden set on the ul will hide all list items apart from the first */
    /* current li height 38px + 5px border */
    height: 68px;

}

.drop-down li {
    border-bottom: 2px solid #8E6E53;
    width: 200px;
    margin: 10px auto;
}

/* .drop-down li a {
    display: block;
    text-decoration: none;
    padding: 10px;
    width: 200px;
    align-items: center;
    color: #22181C;
    font-weight: 900;
    padding: 20px 0px;
    
} */
  
.drop-down1 li {
    border-bottom: 2px solid #8E6E53;
    width: 200px;
    margin: 10px auto;
}

/* .drop-down1 li a {
    display: block;
    text-decoration: none;
    width: 200px;
    align-items: center;
    color: #22181C;
    font-weight: 900;
    padding: 20px 0px;
                 */
    /* Larger touch target area */
/* } */

.drop-down-no li {
    width: 200px;
    margin: 0 auto;
}

.nav-button {
    display: block;
    text-decoration: none;
    padding: 10px;
    width: 200px;
    align-items: center;
    color: #22181C;
    font-weight: 900;
    padding: 25px 0px;
}
.serviceop ul li a{
    text-decoration:none ;
    color: #342211;
    font-size: 1.2rem;
    font-weight: 900;
    font-family: 'Josefin Sans', sans-serif;

}
.serviceop ul li a:hover{
    text-decoration:none ;
    color: white;
    font-size: 1.2rem;
  
}

.drop-down li:first-child a:after {
    content: "▼";
    float: end;
    margin-left: 20px;
    /* Excessive -margin to bring link text back to center */
}

.drop-down1 li:first-child a:after {
    content: "▼";
    float: end;
    margin-left: 20px;
    /* Excessive -margin to bring link text back to center */
    
}

.locationgrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
}

@media (max-width:1024px){
    .locationgrid{
        display: grid;
        grid-template-columns: 1fr 1fr  ;
    }
}
@media (max-width:650px){
    .locationgrid{
        display: grid;
        grid-template-columns: 1fr  ;
    }
}