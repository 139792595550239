.whowrap {
    /* word-break: break-all; */
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 10%;
    
}

.whobg {
    background-color: #F5F5F5;
}

.whogrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}



.whoh h1 {
    font-size: 3rem;
    color: #644222;
    text-decoration-line: overline;
    text-decoration-color: brown;
    font-family: 'Playfair Display', serif;
    
}

.whop p {
    font-size: 1.3rem;
    font-family: 'Josefin Sans', sans-serif;
}


.whoimg {
    margin-bottom: 5%;
}

.whoimg img {
    margin-top: -5%;
    width: 350px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.396);
    border-radius: 30px;
    transition: transform 0.3s ease;
    transform-origin: center;
}

.whosimg:hover {
    transform: scale(1.1);
    /* Adjust the value to control the magnification level */
}

.whobg1 {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 5%;
    padding: 20px;
    padding-top: 0px;
}

.whobgg {
    /* word-break: break-all; */

    background-color: #f0f0f0;
}

.whocontenth {
    font-size: 2.3rem;
    font-family: 'Playfair Display', serif;
}

.whocontent p{
    font-size: 1.1rem;
    font-family: 'Josefin Sans', sans-serif;
}

@media (max-width:768px){
    .whogrid {
        grid-template-columns: 1fr;
    }
    .whoimg{
        margin: 0 auto;
        margin-bottom: 5%;
        }
        .whoimg img{
        width: 250px;
        }

}