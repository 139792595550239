.dream{
    word-break: break-all;
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #FAEBD7;
    margin-bottom: 5%;
}

.dreamimg {
    max-width: 100%;
    text-align: center;
    margin: auto auto;
}

.dreamimg img {
    max-width: 80%; 
    height: auto;
}
.dreamcontent{
    margin: 20px;
    margin-right: 25%;
}

.dreamcontent h1{
    text-transform: uppercase;
    font-family: 'Playfair Display', serif;
    font-size: 2.3rem;
}
.dreamcontent p{
    font-family: 'Playfair Display', serif;
    font-size: 1.2rem;
    font-weight: 900;
}
.dreamcontent span{
    font-size: 1.4rem;
    color: grey;
    font-family: 'Josefin Sans', sans-serif;
}


@media screen and (max-width: 768px) {
    .dream {
        grid-template-columns: 1fr;
    }
    .dreamimg {
        max-width: 100%; 
        height: auto;
        margin: 0 auto;
        padding: 20px;
    }
}

.dreamb button{
    margin-top: 50px;
    margin-left: 20px;
}

.emergencygridwrap0{
    background-color: antiquewhite;
    margin: 0 auto;
    margin-top: 5% ;
    padding: 50px;
}

.energencygridimg0 img{
    max-width: 80%;
    height: auto;
    border-radius: 50px 0px 50px 50px;
    float: right; 
    
}

.energencygridimg0
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}