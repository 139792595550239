.swiper {
    width: 100%;
    height: 50vh;
    background: #000;
  }
  
  .swiper-slide {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
  }
  
  .parallax-bg {
    background-image: img1;
    left: 0;
    top: 0;
    width: 180%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    position:absolute;
  }
  
  .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
  }
  
  .swiper-slide .subtitle {
    font-size: 21px;
  }
  
  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
  }
  .spch1{
    font-family: 'Playfair Display', serif;
    text-align: center;
    font-size: 2.3rem;
}
.spcp{
    margin-top: 5%;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;
}

.spctext{
    background-color: #0000006a;
    padding: 20px;
    width: max-content;
}
.title{
    font-family: 'Playfair Display', serif;
    font-size: 1rem;
}

.text p{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;
}

@media (max-width:425px){
  .text p{
    font-size: 1rem;
  }
  .title h1{
    font-size: 1.4rem;
  }
  .spctext{
    width: 250px;
  }
  .swiper-slide{
    padding: 20px 20px;
  }
}