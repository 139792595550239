@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');

.discoverwrap {
    margin-top: 10%;
    height: fit-content;
    background-color: antiquewhite;
}

.discovergrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

}

.discoverimg{
    display: flex;
    justify-content: center; /* Horizontally center the image */
    align-items: center;
}

.discoverimg img {
    width: 100%;
    height:100%;
}

.discovercontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
    padding: 20px;
    margin-right: 30%;
}

.discovercontent p {
    margin-top: 20px;
}

.discovercontent h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
}

.discovercontent span {
    font-weight: 900;
}

.discoverpara {
    color: rgb(97, 97, 97);
}

.aboutbookbutton {
    margin-top: 10%;
    width: 200px;
}

@media (max-width: 1440px){
    .discoverwrap {
        margin-top: 20%;
    }
    .discovercontent {
        margin-right: 5%;
        margin-top: 2%;
        padding: 20;
    }

}

@media (max-width: 768px) {
    .discovergrid {
        grid-template-columns: 1fr;
    }
.discoverimg{
    padding: 20px;

}
   
    .discovercontent  h1{
        font-size: 2rem;
    }
}