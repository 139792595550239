.veneersgrid{
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 5%;
    padding: 20px;

}

.veneersimg img{
    max-width: 80%;
    height: auto;
    border-radius: 50px 0px 50px 50px;
    display: block; /* Ensures that the image is treated as a block element */
    margin: 0 auto; 
}
.veneersimg1 img {
    max-width: 80%;
    height: auto;
    border-radius: 50px 0px 50px 50px;
    display: block; /* Ensures that the image is treated as a block element */
    margin: 0 auto; /* Center the image horizontally */
}

.veneersbanner{
    margin-top: 5%;
    background-color:blanchedalmond;
    padding: 50px;
    text-align: center;
}

.veneersbancon{
    max-width: 1440px;
    margin: 0 auto;
}
.veneerscontent ul li {
    padding: 10px;
}


.veneersbanner1{
    background-color: #644222;
    padding: 50px;
    text-align: center;
    color: white;
}

.veneersbanner2{
    background-color: whitesmoke;
    padding: 50px;
    text-align: center;
}
.veneerscontentcard{
    text-align: center;
}
.roundcardswrap{
    padding: 20px;
}

.veneerscontent li{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;

}
.veneerscontent p{
    padding-top: 15px;
}

.veneersbancon h1{
    font-size: 3.2rem;
    font-family: 'Playfair Display', serif;
}
.veneersbancon p{
    font-family: 'Josefin Sans', sans-serif;
    margin-top: 2%;
    font-size: 1.4rem;
}
.roundcardswrap h1{
    font-family: 'Playfair Display', serif;
    font-size: 3.2rem;
}
.roundcardswrap p{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;
    font-weight: 900;
}
@media (max-width:768px){
    .veneerscontent h1{
        text-align: center;
    }
    .veneerscontent ul li{
        font-family: 'Josefin Sans', sans-serif;
        font-size: 1.4rem;
        padding: 10px;
    }
    .veneersbancon h1{
        font-size: 2rem;
    }
    .veneersbancon p{
        font-size: 1.2rem;
    }
    .roundcardswrap h1{
        font-size: 2.1rem;
    }
    .veneersbanner1{
        padding: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .veneersbanner2{
        padding: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .veneersbanner{
        padding: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    

}