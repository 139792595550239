@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.navwrap{
    max-width: 1440px;
    margin: 0 auto;
}
.nav-links li {
    list-style: none;
    color: #644222;
    font-size: 1.1rem;
    padding: 0 10px;
    cursor: pointer;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: bolder;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.nav-links li:hover {
    border-bottom: 2px solid #644222;

}
.navbar {
    z-index: 99;
    background-color: #ffe5cc;
    height: max-content;
    line-height: 7vh;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;


}

.navcontainer h3{
    font-size: 2.1rem;
    display: flex;
    align-items: center;
}

.navbar-bg{
    background-color: #ffe5cc;
    position: sticky; 
    top: 0
}



.nav-links {
    display: flex;
    justify-content: center;
    width: 40%;
}

.bookbutton{
    margin-top: 0.5%;
    background-color: #BE9874;
    color: white;
    font-size:1rem;
    width: 30vh;
    height: 5vh;
    border: none;
    cursor: pointer;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.navcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
}

.mobile-menu-icon {
    display: none;
    border: none;
    background: none;
    font-size: 20px;
}

.navcontainer a {
    text-decoration: none;
}

.link-style {
    color: white;
  }
.link-style h3{
    text-decoration: none;
}  

.link-style h3:hover{
    color: white;
}  




@media (max-width:1440px){
.bookbutton{
    margin-right: 2%;
}

} 
@media (max-width:768px) {
    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
        left: 0;
        height: 100vh;
        top: 10%;
        transition: all 0.5s ease-in-out;
        width: 100%;
        background-color: white;
        z-index: 1000; /* Add a higher z-index value */
        overflow: scroll;

    }
    .logo{
        margin-bottom:10px ;
    }

    .nav-links,.bookbutton {
        display: none;
    }

   .navcontainer ul li {
        padding: 32px;
        width: 100%;
        transition: all 0.5s ease-in-out;
        animation: sizefont 1s ease-in-out;
        text-align: center;
        font-size: 2rem;
        color: #644222;
        font-family: 'Playfair Display', serif;
    }

    @keyframes sizefont {
        0%{
            font-size: 1rem;
        }
        100%{
            font-size: 2rem;
        }
        
    }
  
    .mobile-menu-icon {
        color:#644222;
        position: absolute;
        display: flex;
        align-items: center;
        right: 20px;
    }

    .zz{
        padding-bottom: 5%;
        margin-top: -1%;
        padding-top: 3%;
        background-color: #644222;
    }

    .bookzzbutton{
        display: flex;
        margin: 0 auto;
        padding: 9px;
        border-radius: 5px;
        color: #fff;
        background-color:rgb(190,151,116);
        font-size: 1.2rem;
        font-family: 'Josefin Sans', sans-serif;
        border: none;
    }
    .zzp{
        margin: 2% auto;
        text-align: center;
        color: white;
        font-size: 1.2rem;
        font-family: 'Josefin Sans', sans-serif;
    }
   
}


Add a light background color for the services dropdown
.services-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff; /* Light background color */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 5px 5px;
    z-index: 1;
    padding: 0; /* Optional: Adjust padding as needed */
  }
  
  /* Add styles for individual service options */
  .services-dropdown li {
    padding: 10px 20px;
    text-align: left;
    list-style-type: none;
    border-bottom: 1px solid #ccc; /* Optional: Add a border between options */
  }
  
  /* Style the last service option to remove the border */
  .services-dropdown li:last-child {
    border-bottom: none;
  }
  
  /* Add hover effect for service options */
  .services-dropdown li:hover {
    background-color: #f0f0f0; /* Lighter background color on hover */
  }
  