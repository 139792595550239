.orthocolor {
    background-color: whitesmoke;
}

.orthowrap {
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px;
}

.orthogrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.orthoimg img {
    max-width: 80%;
    height: auto;
    border-radius: 0px 50px 50px 50px;
    display: block; /* Ensures that the image is treated as a block element */
    margin: 0 auto;
}

.braces {
    margin-top: 5%;
    background-color: white;
}

.braceswrap {
    max-width: 1440px;
    margin: 0 auto;
}

.extrainfowrap {
    background-color: #F9EAD7;
    margin-top: 5%;
}

.extrainfo {
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
}

.checkeligiblegrid {
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-left: 20px;
}

.checkeligiblewrap {
    max-width: 1440px;
    padding: 30px;
    margin: 0 auto;

}

.eligibleimg img {
    max-width: 80%;
    height: auto;
    border-radius: 0px 50px 50px 50px;
    display: block; /* Ensures that the image is treated as a block element */
    margin: 0 auto;
}

.roundcardscolor {
    background-color: whitesmoke;
}

.roundcardswrap {
    max-width: 1440px;
    margin: 5% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.roundcardswrap h1{
    color: #644222;
    text-align: center;
}

.rcard {
    padding: 20px;
    align-items: center;
    text-align: center;
    background-color: #F9EAD7;
    width: 300px;
    height: max-content;
    border-radius: 60px;
}

.rcard img {
    width: 200px;
    height: 200px;
    object-fit: contain;
}


.orthocontainer {
    margin-top: 32px;
    width: 600px;
    /* Adjust the width as needed */
    height: max-content;
    /* Adjust the height as needed */
    background-color: #F9EAD7;
    /* Rectangle background color */
    border-radius: 25px 0 0 25px;
    /* Rounded corners on the left side */
    position: relative;
}

.orthocircle {
    width: 100px;
    /* Adjust the circle's diameter as needed */
    height: 100px;
    /* Adjust the circle's diameter as needed */
    background-color: #644222;
    /* Circle background color */
    border-radius: 50%;
    /* Makes it a circle */
    position: absolute;
    top: 25px;
    /* Adjust the top position to align with the rectangle */
    right: -50px;
    /* Adjust the right position to overlap with the rectangle */
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.orthocircle1 {
    width: 100px;
    /* Adjust the circle's diameter as needed */
    height: 100px;
    /* Adjust the circle's diameter as needed */
    background-color: #644222;
    /* Circle background color */
    border-radius: 50%;
    /* Makes it a circle */
    position: absolute;
    top: 25px;
    /* Adjust the top position to align with the rectangle */
    left: -50px;
    /* Adjust the right position to overlap with the rectangle */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Style for text inside the rectangle */
.orthotext {
    display: flex;
    text-align: left;
    padding: 5%;
    padding-left: 60px;
    padding-right: 60px;
    /* Adjust padding as needed */
    color: #644222;
    /* Text color */

}

.orthotext1 {
    display: flex;
    text-align: left;
    padding: 5%;
    /* Adjust padding as needed */
    padding-left: 60px;
    padding-right: 60px;
    color: #644222;
    /* Text color */

}

/* Style for image inside the circle */
.orthocircle img {
    width: 80%;
    filter: invert(100%);
    padding: 5px; 
}

.orthocircle1 img {
    width: 80%;
    filter: invert(100%);
    padding: 5px; 
}


.orthocontent h1,
.bracescontent h1,
.extrainfocon h1,
.eligibecon h1 {
    font-size: 3.2rem;
    font-family: 'Playfair Display', serif;
    color: #644222;
}

.orthocontent p,
.bracescontent p,
.extrainfocon p,
.eligibecon p {
    font-size: 1.4rem;
    font-family: 'Josefin Sans', sans-serif;
}

.bracescontent li,
.eligibecon li {
    font-size: 1.4rem;
    font-family: 'Josefin Sans', sans-serif;
    margin-top: 2%;
}

.extrainfocon {
    padding: 100px;
}
.bracescontent {
    padding: 20px;
}
.bracescontent ul li{
    padding: 5px;
    padding-left: 10px;
    list-style: none;
}

@media (max-width:768px) {
    .orthogrid {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }
    .orthocontent h1, .bracescontent h1 , .extrainfocon h1, .eligibecon h1{
        font-size: 2.3rem;

    }
    .bracescontent ul li{
        padding: 5px;
        padding-left: 20px;
        list-style: none;
    }
    .extrainfocon{
        padding: 20px;
    }

.checkeligiblegrid {
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-left: 20px;
}

.checkeligiblewrap {
    max-width: 1440px;
    padding: 20px;
    margin: 0 auto;

}

}
@media (max-width:425px) {
    .orthocontainer {
        margin-top: 10%;
        width: 300px;
        /* Adjust the width as needed */
        height: max-content;
        /* Adjust the height as needed */
        background-color: #F9EAD7;
        /* Rectangle background color */
        border-radius: 25px 0 0 25px;
        /* Rounded corners on the left side */
        position: relative;
        margin-bottom: 10%;
    }

   
    .orthocircle {
        width: 100px;
        /* Adjust the circle's diameter as needed */
        height: 100px;
        /* Adjust the circle's diameter as needed */
        background-color: #644222;
        /* Circle background color */
        border-radius: 50%;
        /* Makes it a circle */
        position: absolute;
        top: -50px;
        /* Adjust the top position to align with the rectangle */
        right: -50%;
        left: 35%;
        /* Adjust the right position to overlap with the rectangle */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .orthocircle1 {
        width: 100px;
        /* Adjust the circle's diameter as needed */
        height: 100px;
        /* Adjust the circle's diameter as needed */
        background-color: #644222;
        /* Circle background color */
        border-radius: 50%;
        /* Makes it a circle */
        position: absolute;
        top: -50px;
        /* Adjust the top position to align with the rectangle */
        right: -50%;
        left: 35%;
        /* Adjust the right position to overlap with the rectangle */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* Style for text inside the rectangle */
    .orthotext {
        display: flex;
        text-align: left;
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
        /* Adjust padding as needed */
        color: #644222;
        /* Text color */

    }

    .orthotext1 {
        display: flex;
        text-align: left;
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
        /* Adjust padding as needed */
        color: #644222;
        /* Text color */
    }
}