.aboutheading {
    height: 50vh;
    background-image: url(../images/bsi3.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    background-color: #322417;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.name {
    display: none;
}

.hoverimg {
    margin-top: 0;
    filter: invert(100%);
}

.bgimg {
    width: 100px;
}

.aboutname {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 20px;

}

.aboutheading h1 {
    font-size: 3.8rem;
    text-transform: uppercase;
    font-family: 'Playfair Display', serif;

}

.aboutheading p {
    margin-top: 1%;
    font-size: 2rem;
    font-family: 'Josefin Sans', sans-serif;
    color: #fffefe61;
    background: -webkit-gradient(linear, left top, right top, from(#e6e6e6), to(#1d1c1c), color-stop(0.8, #ffffff)) no-repeat;
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.8, #fff)) no-repeat;
    background-size: 11px 100%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: flick 2.5s infinite ease-in-out;
}



@media (max-width:1440px) {
    .aboutheading img {
        display: flex;
    }
}

@media (max-width:768px) {
    .aboutheading {
        height: max-content;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .aboutheading h1,
    .aboutheading p {
        display: none;
    }

    .aboutname {
        margin-top: 50%;
    }

    .name {
        display: block;
        text-align: center;
        background-color: #4f3b29;
        padding: 2%;
        color: white;
        
    }
    .name h1{
        font-family: 'Playfair Display', serif;

    }

    .name p {
        margin-top: 1%;
        font-size: 1rem;
        font-family: 'Josefin Sans', sans-serif;
        color: #fffefe61;
        background: -webkit-gradient(linear, left top, right top, from(#e6e6e6), to(#1d1c1c), color-stop(0.8, #ffffff)) no-repeat;
        background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.8, #fff)) no-repeat;
        background-size: 11px 100%;
        -webkit-background-clip: text;
        background-clip: text;
        animation: flick 3.5s infinite ease-in-out;
    }

    .aboutheading img {
        display: none;
    }
}

@media (max-width:425px) {
    .aboutheading h1 {
        font-size: 2rem;
    }

    .aboutheading p {
        font-size: 1rem;
    }

    .aboutheading {
        height: max-content;
    }
}

@keyframes flick {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}