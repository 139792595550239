.energencycolor{
    background-color: #f2eeea;
}
.emergencycolor{
    background-color: #735234;
}

.emergencywrap{
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;  
    margin-top: 5%;
    padding: 7%;
    color: #644222;
}
.emergencygridwrap{
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 5% ;
    padding: 20px;
}

.emergencygrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.energencygridimg img{
    max-width: 80%;
    height: auto;
    border-radius: 50px 0px 50px 50px;
    float: right; 
    
}

.energencygridimg
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.emergencywrap h1{
    font-size: 4rem;
    font-family: 'Playfair Display', serif;
    color: #644222;
}
.emergencywrap p{
    font-size: 1.2rem;
    font-family: 'Josefin Sans', sans-serif;
    margin-top: 2%;
}
.energencygridcon p{
    font-size: 1.2rem;
    font-family: 'Josefin Sans', sans-serif;
    margin-top: 2%;
}

.energencygridcon h1{
    font-size: 4rem;
    font-family: 'Playfair Display', serif;
    color: #644222;
}

.af{
    margin-top: 5%;
}

.spbbottom h1{
    font-family: 'Playfair Display', serif;
}
.spbbottom p{
    font-family: 'Josefin Sans', sans-serif;
}
.servicesdiv{
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .emergencywrap h1{
        font-size: 2.3rem;
        text-align: center;
    }
    .energencygridcon h1{
        font-size: 2.3rem;
        text-align: center;
    }
    .emergencygrid{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}