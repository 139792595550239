@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');
.servicesdiv {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 0 auto;
    max-width: 1440px;

}

.servicescontent {
    text-align: center;
}

.contentcard {
    background-color: #ffffff;
    height:max-content;
    margin-top: 2%;
    transition: transform 0.3s ease-in-out;
    box-shadow: 2px 2px 5px rgb(122, 122, 122);
}

.contentcard:hover {
    transform: scale(1.1);
}

.insidecard {
    margin-left: 2%;
    padding: 20px;
}

.insidecard p {
    margin-top: 3%;
    font-family: 'Prompt', sans-serif;

}

.servicename {
    margin-top: 3%;
    font-family: 'Prompt', sans-serif;
}

.serviceimg {
    height: 100px;
    z-index: 2;
}

.servicebgimg {
    position: relative;
    margin-left: 40%;
    height: 125px;
    z-index: 1;
}
.links{
    text-decoration: none;
    color: #442226;
    font-family: 'Prompt', sans-serif;

}

.servicename {
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .servicesdiv {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .servicesdiv {
        grid-template-columns: repeat(1, 1fr);
    }
}
