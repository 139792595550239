@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
.serviceswrap {
    padding-top: 100px;
    max-height: max-content;
    background-color: antiquewhite;
    margin-top: 2%;
}

.theservices{
    text-transform: uppercase;
    font-size: 3rem;
    font-family: 'Playfair Display', serif;
}

.servicesdes {
margin: 0 auto;
    margin-bottom: 80px;
    max-width: 800px;
    font-family: 'Quicksand', sans-serif;
}

.serviceshoverimg {
    position: absolute;
    display: flex;
    left: 0;
    width: 10%;
    margin-top: 15%;
    animation: floatAnimation 5s ease-in-out infinite;
  }
  
  @keyframes floatAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width:1440px){
    
.serviceshoverimg {
 width: 0px;
}

  }