.abovef button {
    margin-top:10px;
}
.abovef h1{
    font-size: 2.5rem;
    text-align: center;
    font-family: 'Playfair Display', serif;
}
.abovef{
    max-width: 1440px;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
}