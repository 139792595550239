.generalwrap{
    max-width: 1440px;
    margin: 0 auto;
}
.generalgrid{
    display: grid;
    grid-template-columns: 0.8fr 1fr;
}

.generaldenser{
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
    padding: 10%;
    background-color: #644222;
    color: white;
}
.gendenser{
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px;
    gap: 20px;
}
.card-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 100%;
}

.card {
    width: 400px; /* Adjust the card width as needed */
    height: 550px; /* Adjust the card height as needed */
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50px;
    margin: 0 auto;
  }
  
  .card-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    transition: filter 0.3s ease;
  }
  
  .card:hover .card-image {
    filter: grayscale(100%);
  }
  
  .card-heading {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease;
  }
  
  .card:hover .card-heading {
    transform: translateY(-510%);
  }
  
  .card-description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 10px;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .card:hover .card-description {
    opacity: 1;
  }
  
  .genbookgrid {
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    background-color: bisque;
    gap: 50px;
    padding: 20px;
    padding-bottom: 0;
}

.genbookcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Align text to the start of the column */
}

.genbookimg {
    display: flex;
    align-items: flex-end; /* Align image to the bottom of the grid cell */
}

.genbookimg img {
    max-width: 100%; /* Allow the image to occupy the full width */
    height: auto; /* Maintain the image's aspect ratio */
}

.veneerscontent h1{
    font-size: 4rem;
    color: #644222;
    font-family: 'Playfair Display', serif;
}

.veneerscontent p{
    font-size: 1.4rem;
    font-family: 'Josefin Sans', sans-serif;
}

.generaldenser p{
    font-size: 1.4rem;
    font-family: 'Josefin Sans', sans-serif;
}
.generaldenser h1{
    font-size: 4rem;
    font-family: 'Playfair Display', serif;
}
.card h2{
    font-size: 2rem;
    font-family: 'Playfair Display', serif;
}
.card p{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
}
.genbookcon h1{
    font-size: 6rem;
    font-family: 'Playfair Display', serif;
    color: #644222;

}
.genbookcon p{
    font-size: 1.4rem;
    font-family: 'Josefin Sans', sans-serif;
}

@media (max-width:768px){
    .veneersgrid{
        display: flex;
        flex-direction: column-reverse;
    }
    .veneerscontent h1{
        font-size: 2rem;
    }
    .generaldenser h1{
        font-size: 2.3rem;
    }
    .generaldenser p{
        font-size: 1rem;
    }
    .gendenser{
        max-width: 1440px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        padding: 20px;
        gap: 20px;
    }
    .card {
        width: 350px; /* Adjust the card width as needed */
        height: 550px; /* Adjust the card height as needed */
        position: relative;
        overflow: hidden;
        cursor: pointer;
        border-radius: 50px;
        margin: 0 auto;
      }
      .genbookgrid {
        max-width: 1440px;
        margin: 5% auto;
       display: flex;
       flex-direction: column-reverse;
        background-color: bisque;
        gap: 50px;
        padding: 20px;
        padding-bottom: 0;
    }
      .genbookcon h1{
        font-size: 2.3rem;
        font-family: 'Playfair Display', serif;
        color: #644222;
    
    }
    .genbookcon p{
        font-size: 1.4rem;
        font-family: 'Josefin Sans', sans-serif;
    }
    .genbookimg img {
        max-width: 70%;
        height: auto;
        display: block; /* Ensures that the image is treated as a block element */
        margin: 0 auto;  /* Maintain the image's aspect ratio */
    }
}

@media (max-width:425px){
    .gendenser{
        max-width: 1440px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr  ;
        padding: 0px;
        gap: 20px;
    }
    .card {
        width: 350px; /* Adjust the card width as needed */
        height: 550px; /* Adjust the card height as needed */
        position: relative;
        overflow: hidden;
        cursor: pointer;
        border-radius: 50px;
        margin: 0 auto;
      }
}
@media (max-width:350px){
    .gendenser{
        max-width: 1440px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr  ;
        padding: 0px;
        gap: 20px;
    }
    .card {
        width: 300px; /* Adjust the card width as needed */
        height: 550px; /* Adjust the card height as needed */
        position: relative;
        overflow: hidden;
        cursor: pointer;
        border-radius: 50px;
        margin: 0 auto;
      }
}