@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

.abovenav {
    background-color: #644222;
    padding: 10px;
}

.aboveitems {
    color: white;
    font-size: 1.1rem;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    
    margin: 0 auto;
    align-items: center;
    font-style: oblique;
}

.timings {
    font-family: 'Roboto Condensed', sans-serif;
}
.timings a {

    font-family: 'Roboto Condensed', sans-serif;
    color: white;
}

.socials {
    font-size: 1.2rem;
    margin: 0 10px;
}

.socialicons a{
    color: white;
}

@media (max-width:768px){
    .abovenav{
        display: none;
    }
}