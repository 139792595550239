.offercontainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Display 3 cards per row by default */
    grid-gap: 20px;
  }
  
  .offerscard {
    background-color:white;
    border: 5px dashed #644222;
    padding: 30px;
    border-radius: 10px;
}

.offerheading h1 {
    font-family: 'Playfair Display', serif;
    font-size: 1.9rem;
}

.offersubheading h2 {
    font-family: 'Playfair Display', serif;
    font-size: 1rem;
}

.offerprice {
    margin-top: 20px;
    font-size: 3rem;
}

.offerprice span{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color: #644222;
}

.offerdes {
    margin-top: 5%;
    line-height: 50px;
}

.offerdes ul {
    padding-left: 5%;
    list-style-type: "✔ ";
    color: rgb(57, 57, 57);
}

.offerbook {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .bookzbutton {
    background-color: #644222;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .offercontainer {
      grid-template-columns: repeat(2, 1fr); /* Display 2 cards per row on smaller screens */
    }
  
    .offerscard {
      padding: 20px;
    }
  
    .offerheading h1 {
      font-size: 1.2rem;
    }
  
    .offersubheading h2 {
      font-size: 0.9rem;
    }
  
    .offerprice {
      font-size: 2rem;
    }
  
    .offerdes {
      line-height: 40px;
    }
  
    .offerdes ul {
      padding-left: 3%;
    }
  
    .offerbook {
      justify-content: flex-start;
    }
  }
  