.cc1grid{
    max-width: 768px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 5%;
    margin-bottom: 10%;
}
.cc1grid button{
    cursor: pointer;
}
.cc1{
    background-color: blanchedalmond;
    border: 2px solid black;
    padding-right: 5%;
    padding-left: 5%;

}
.cc1 img{
    height: 50px;
}
.cc1 h1{
    font-size: 1.4rem;
    margin: 0;
}
.cc1 p{
    font-size: 0.8rem;
}