.indiofferwrap{
    max-width: 1140px;
    background-color:#644222;
    margin: 0 auto;
    border: 5px dashed blanchedalmond;
    border-radius: 70px;
   
}
.indiofferwrap1{
    background-color:blanchedalmond;
    margin: 20px;
    border-radius: 50px;
  
}
.indiofferwrap h1{
    text-align: center;
    padding-top:50px ;
    font-size: 3.2rem;
    font-family: 'Playfair Display', serif;
    
}
.indiofferimg img{
    max-width: 80%;
    height: auto;
    display: block; /* Ensures that the image is treated as a block element */
    margin: 0 auto;
    
    
}
.indigrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    padding: 50px;
}
.indioffercon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.indioffercon h2 {
    font-size: 3.2rem;
    text-align: center;
    line-height: 1.2; /* Adjust the line-height value as needed */
    font-family: 'Playfair Display', serif;
}

.indioffercon h3 {
    font-size: 2.3rem;
    text-align: center;
    line-height: 1.2; /* Adjust the line-height value as needed */
    font-family: 'Playfair Display', serif;
}
.indiofferrwrap{
    padding: 20px;
}


.indioffercon h4{
    text-align: center;
    font-family: 'Playfair Display', serif;
}


@media (max-width:1024px){
    .indioffercon h2 {
        font-size: 2.3rem;
        text-align: center;
        font-family: 'Playfair Display', serif;
    }
    
    .indioffercon h3 {
        font-size: 2.3rem;
        text-align: center;
        font-family: 'Playfair Display', serif;
    }
}

@media (max-width:768px){
    .indigrid{  
          grid-template-columns: 1fr;
    }
    .indioffercon h2 {
        font-size: 2.3rem;
        text-align: center;
        font-family: 'Playfair Display', serif;
    }
    
    .indioffercon h3 {
        font-size: 2.3rem;
        text-align: center;
        font-family: 'Playfair Display', serif;
    }
}

@media (max-width:375px){
    .indigrid{  
          grid-template-columns: 1fr;
    }
    .indioffercon h2 {
        font-size: 1.4rem;
        text-align: center;
        font-family: 'Playfair Display', serif;
    }
    
    .indioffercon h3 {
        font-size: 1.4rem;
        text-align: center;
        font-family: 'Playfair Display', serif;
    }

   
    .indigrid{
        
        padding: 10px;
    }
    .indiofferwrap h1{
        text-align: center;
        padding-top:20px ;
        font-size: 2.3rem;
        font-family: 'Playfair Display', serif;
        
    }
}