@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');

.offerswrap {
    max-width: 1440px;
    margin: 0 auto;
}
.offerr{
    background-color:whitesmoke;

}

.offersgrid {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.offerscontent {
    text-align: center;
    margin-top: 5%;
}

.bestprices{
    font-family: 'Playfair Display', serif;
}

.offerscontent span {
    font-weight: bold;
    font-family: 'Playfair Display', serif;
}

.offerscontent h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
}

.offerscontent p {
    font-family: 'Playfair Display', serif;
    font-size: 1.2rem;
    max-width: 700px;
}

.offercards {
    padding-top: 100px;
    width: 100%;
    gap: 20px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
  
}

.offer {
    background-color: #fff3de;
    border: 5px dashed #564d3d;
    padding: 30px;
}

@media (max-width: 1024px) {
    .offercards {
        grid-template-columns: 1fr 1fr;
        /* Display 1 card per row on smaller screens */
    }
}

@media (max-width: 768px) {
    .offercards {
        grid-template-columns: 1fr;
        /* Display 1 card per row on smaller screens */
    }

}