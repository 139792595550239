@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&family=Playfair+Display:wght@500&display=swap');

.aboutwrap {
    padding: 0 2%;
    display: flex;
    column-gap: 20vh;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 5%;
    padding: 20px;
}

.aboutcontainer {
    position: relative;
    display: inline-block;
}
.hoverimg {
    position: absolute;
    display: flex;
    right: 0;
    width: 10%;
    margin-top: 15%;
    animation: floatAnimation 5s ease-in-out infinite;
  }
  
  @keyframes floatAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20%);
    }
    100% {
      transform: translateY(0);
    }
  }

.img1 {
    width: 400px;
    position: relative;
    z-index: 1;
    border-radius: 10px;
}

.mainheading h2 {
    text-transform: uppercase;
    font-size: 3rem;
    font-family: 'Playfair Display', serif;
}
.contentz{
    color: grey;
}
.aboutlist {
    list-style: none;
    margin-top: 2%;
    font-size: 1.2rem;
}

.abtlist:before {
    content: '✓ ';
    color: #644222;
}

.aboutbookbutton{
    margin-top: 0.5%;
    background-color: #644222;
    color: white;
    font-size:1rem;
    width: 30vh;
    height: 5vh;
    border: none;
    cursor: pointer;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 5%;
}

.signature{
    font-family: 'Italianno', cursive;
    font-size: 2rem;
    display: inline-block;
    margin-left: 80%;
}

@media screen and (max-width: 1440px) {
    .hoverimg {
        display: none;
    }
}
@media screen and (max-width: 1024px) {
    .img1{
        width: 300px;
        height: 500px;
    }

    .aboutwrap{
        column-gap: 20vh;
    }
}
@media screen and (max-width: 900px){
    .aboutcontainer{
        display: flex;
    justify-content: center;   
}
    .img1{
        width: 400px;
        height: 550px;
    }
   
.aboutcontent{
    margin-top: 10%;
}

    .aboutwrap{
        display: block;
    }

}

@media screen and (max-width: 560px){
    .img1{
        max-width: 100%;
        max-height: 100%;
        display: block;
    }
    .mainheading h2{
        font-size: 2rem;
    }
    .contentz{
        font-size: 1.1rem;
    }
}