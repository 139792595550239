.cleanofferwrap{
    max-width: 1440px;
    padding: 20px;
    display: flex;
    align-items: center;
    margin: 0 20px;
}
.spg1 img{
    border-radius: 60px 0px 60px 60px;
}

.cleanofferimg img{
    height: 550px;
    border-radius: 50px 0px 50px 50px;
}
.cleanoffercontent{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cleanoffer{
    display: grid;
    grid-template-columns:1fr 1fr ;
    gap: 20px;
}

@media (max-width:768px){
    .cleanoffer{
        display: grid;
        grid-template-columns:1fr ;
        gap: 20px;
    }

    .cleanofferimg img{
        max-width: 90%;
        height: auto;
        border-radius: 50px 0px 50px 50px;
    }
}

.cleanoffercontent h1{
    font-size: 4rem;
    color: #644222;
    font-family: 'Playfair Display', serif;

}

.cleanoffercontent p{
    font-size: 1.4rem;
    font-family: 'Josefin Sans', sans-serif;
    margin-top: 2%;

}
@media (max-width:768px){

    .cleanoffercontent h1{
        font-size: 2rem;
        text-align: center;

    }
    .cleanofferwrap{
        padding: 0px;
    }
}