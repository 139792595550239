@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

.syscontainer {
    background-color: #644222;
    height: max-content;
    color: rgb(255, 255, 255);
    
}

.sysbg {
    max-width: 1440px;
    padding: 20px;
    padding-bottom: 90px;
    margin: 0 auto;
    text-align: center;

}

.sysgrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    color: black;
    margin-top: 7%;
}

.sysdiv h1 {
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
    font-size: 2.3rem;
}
.sysdiv{
    margin-top: 7%;

}

.sysdiv p {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2rem;

}

.syscard {
    height: max-content;
    background-color: antiquewhite;
    border: 5px dashed #644222;
    border-radius: 30px;
    box-shadow: 10px 10px 10px black;
    
}

.syscard img {
    padding-top: 20px;
    width: 250px;
    border-radius: 300px;
}

.syscardh {
    color: #644222;
    margin-top: 0;
    font-family: 'Playfair Display', serif;
}

.syscardsub {
    display: flex;
    max-height: 0;
    overflow: hidden;
    font-family: 'Josefin Sans', sans-serif;
    transform: translate3d(0, 0, 0);
    padding: 0 20px;
    padding-bottom: 2%;
    padding-top: 2%;
}

.syscard:hover .syscardsub {
    max-height: 400px;
}

.syswrap{
    margin-bottom: 5%;
}

.syscardno {
    height: 50px;
    width: 50px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: -15px;
    border: 4px dashed #644222;
    font-size: 2rem;
    border-bottom: none;
    border-right: none;
    color: #644222;
    font-family: 'Dancing Script', cursive;
    font-weight: 900;

}

@media (max-width:768px) {
    .sysgrid {
        grid-template-columns: 1fr;
    }
}