.teethwhitewrap{
    background-color: #644222;
    
}

.teethwhitecon{
    max-width: 1440px;
    padding: 100px;
    margin: 0 auto;
    color: white;
}

.teethwhitecon h1{
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 3.2rem;

}

.teethwhitecon p{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;
    margin-top:3% ;
}

.teethwhitecon li{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;
    margin-top:1% ;
}

.teethp{
    margin-top: 3.2%;
    text-align: center;
}
.teethp p{
    font-size:2rem ;
}

@media (max-width:768px){
    .teethwhitecon{
        padding: 20px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .teethwhitecon h1{
        text-align: center;
        font-family: 'Playfair Display', serif;
        font-size: 2.3rem;
    
    }
    .teethp p{
        padding-top: 50px;
        font-size:1.5rem ;
    }
}