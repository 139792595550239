html { scroll-behavior:smooth;}
.ccwrap{
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px;
    margin-top: -2%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10%;
    background-color: bisque;
    border-radius: 50px;
    box-shadow: 10px 10px 20px black;
}
.ccwwrap{
    padding: 20px;
    margin-bottom: 5%;
}

.ccimg{
    height: 100px;
    width: 100px;
    /* filter: drop-shadow(5px 5px 10px rgb(41, 41, 41)); */
}
.cc{
    display: flex;
    flex-direction: column;
    border: 2px dashed #644222;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
}
.cch1{
    text-align: center;
    padding: 30px;
}
.cch1 h1{
    font-family: 'Playfair Display', serif;
}

.cch1 p{
    font-family: 'Josefin Sans', sans-serif;
}

@media (max-width:768px){
    .ccwrap{
        grid-template-columns: 1fr ;
        margin-top: -10%;
        gap: 0%;
        }
    .ccwwrap{
        padding: 20px;
    }
    .cc{
        margin-top: 10%;
    }
}