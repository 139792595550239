.whygif{
    filter: sepia(100%);
    width: 80px;
    height: 80px;
    margin: 20px;
    margin-top: 3%;
}
.whycard{
    display: flex;
}
.whyh{
    font-family: 'Playfair Display', serif;
}
.whyp{
    padding-top: 5%;
    font-size: 1rem;
    padding-left: 5px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.1rem;
}