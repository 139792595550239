@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');
.paymentsec {
    border: 5px dashed black;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Adjust the column width as needed */
    max-width: 1400px;
    margin: 0 auto;
    /* Center the container horizontally */
}

.payment-plan-container {
    padding: 20px;
    background-color:#F5F5F5;
}

.paymentsec1,
.paymentsec2 {
    padding: 20px;
    background-color: antiquewhite;
}

.paymentsec1 h4 {
    font-family: 'Playfair Display', serif;
    font-size: 4rem;
    margin: 0;
}

.paymentsec2>p {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    margin: 0;
}

.paymentsec3,
.paymentsec4 {
    padding: 20px;
    background-color: rgb(255, 255, 255);
}


.paymentsec2 button {
    margin-top: 10px;
}

.paymentsec3 {
    padding-right: 15%;
}

.paymentsec4 {
    padding-left: 15%;
}

.paymentsec3 h4 {
    font-family: 'Playfair Display', serif;
    font-size: 2.3rem;
}

.paymentsec3 p {
    font-family: 'Playfair Display', serif;
    font-size: 1.4rem;
}

.paymentsec4 h4 {
    font-family: 'Playfair Display', serif;
    font-size: 2.3rem;
}

.paymentsec4 ul {
    font-family: 'Playfair Display', serif;
    font-size: 1.2rem;
}

.button-28 {
    appearance: none;
    background-color: transparent;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 60px;
    min-width: 180px;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 30%;
    will-change: transform;
}

.button-28:disabled {
    pointer-events: none;
}

.button-28:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.button-28:active {
    box-shadow: none;
    transform: translateY(0);
}
.paymentsec{
    margin-top: 2%;
}
@media (max-width: 600px) {
    .paymentsec1 h4 {
    font-size: 2rem;
    margin: 0;
}
}

@media (max-width: 768px) {
    .paymentsec {
        grid-template-columns: 1fr;
    }
}