.dentalwrap{
    max-width: 1440px;
    padding: 20px;
    margin: 0 auto;
}
.dentalgrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media (max-width:768px)
{
    .dentalgrid{
        display: grid;
        grid-template-columns: 1fr;
    }

}

.seropcon {
padding: 0;
    margin: 0 auto;
    background-color: #644222;
    
    
}
.seropcon h1{
    max-width: 1440px;
    font-size: 3.2rem;
    color: white;
    font-family: 'Playfair Display', serif;
    padding: 60px;


}
.seropcon p{
    
    font-size: 1.4rem;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    padding: 60px;
    padding-top: 0;


}

@media (max-width:768px){
    .seropcon h1{
        max-width: 1440px;
        font-size: 2.2rem;
        text-align: center;
        color: white;
        font-family: 'Playfair Display', serif;
        padding: 20px;
    }
    .seropcon p{
        font-size: 1.4rem;
        color: white;
        font-family: 'Josefin Sans', sans-serif;
        padding: 20px;
        padding-top: 0;
    }
   
}