.spwrap {
    background-color: white;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 5%;
    margin-bottom: 10%;
}

.spbanner img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.spbbottom {
    max-width: 70%;
    padding: 20px;
    padding-bottom: 2%;
    border-bottom: 5px solid #644222;
    background-color: antiquewhite;
    position: relative;
    /* Add this line to create a new stacking context */

}

.spbbwrap {
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px;
    margin-top: -8%;
    z-index: -1;
}

.spg1 {
    display: flex;
}

.spg1 h1 {
    padding: 10%;
    padding-bottom: 0;
    font-family: 'Playfair Display', serif;
    font-size: 2.2rem;
}
.spg1 p {
    padding: 5%;
    padding-left: 10%;
    font-size: 1.4rem;
}
.spg1con p{
    font-family: 'Josefin Sans', sans-serif;

}

.spg1 img {
    height: 350px;
    filter: drop-shadow(10px 10px 10px black);
    margin-top: 100px;
    padding-left: 20px;
}

.spservicename {
    padding: 20px;
    background-color: whitesmoke;
    width: 350px;
    font-size: 2rem;
    transition: border-right 0.3s ease;
    border: 3px solid transparent;
    /* Transparent color to hide the border initially */
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.spservicename:hover {
    border: 3px solid bisque;
    border-bottom: 1px solid black;
    box-shadow: 0px 2px 5px rgb(49, 49, 49);
    /* The border color you want when hovering */
}

.spotherservices h1 {
    text-align: center;
}

.spicon {
    font-size: 3rem;
    justify-content: center;
    align-items: center;
}


.spotherservices p {
    text-align: center;
}

.spgrid {
    display: grid;
    grid-template-columns: 1fr 0.4fr;
    gap: 20px;
    margin-top: 10%;
    padding: 20px;
}

.spinnergrid1 {
    padding: 20px;
    display: flex;
}

.spinnergrid1 p {
    font-size: 1.3rem;
    padding-top: 5%;
}

.spinnergrid1 img {
    max-width: 30%;
    margin-left: 8%;
    border-radius: 50px;

}

.spc {
    margin-top: -50px;
    padding: 30px;
    max-width: 1440px;
    margin: 0 auto;
}

.spcc {
    background-color: #FAEBD7;
}

.spo {
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
.spg1con h1{
    font-size: 4rem;
    color: #644222;
}

@media (max-width:768px) {
    .spbbottom {
        margin: 0 auto;
    }

    .spg1 {
        display: block;
        margin: 0 auto;
    }
    .spg1con h1{
        font-size: 2.3rem;
        text-align: center;
    }
    .spgrid {
        grid-template-columns: 1fr;
    }

    .spotherservices {
        display: none;
    }

    .spinnergrid1 {
        display: block;
    }

    .spinnergrid1 img {
        border-radius: 100px;
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        max-width: 80%;
    }

}

@media (max-width:425px){
    .spbbwrap{
        margin-top: -50%;
    }
    .spbbottom{
        max-width: 95%;
    }
    .spg1 img{
        height: 200px;
    }
    .spinfosec h1{
        font-size: 1.4rem;
    }
   


}

