.bottomcontainer {
    background-color: rgb(255, 255, 255);
    box-shadow: 10px -10px 100px rgba(0, 0, 0, 0.548);
    height: 20vh;
    width: 70%;
    margin: 0 auto;
    margin-top: 5%;
    overflow: hidden;
}

.countsgrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.countscontainer1 {
    padding-top: 5vh;
    text-align: center;
    border-right: 3px dashed grey;
}

.countscontainer2 {
    padding-top: 5vh;
    text-align: center;
    border-right: 3px dashed grey;
}

.countscontainer3 {
    padding-top: 5vh;
    text-align: center;
}

.countscontainer1 h3 {
    font-size: 3rem;
}

.countscontainer2 h3 {
    font-size: 3rem;
}

.countscontainer3 h3 {
    font-size: 3rem;
}

@media (max-width: 1150px) {
    .countscontainer {
        font-size: 0.6rem;
    }
}

@media (max-width: 768px) {
    .countsgrid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .bottomcontainer {
        width: 90%;
        height: fit-content;
    }

    .countscontainer1 {
        font-size: 0.6rem;
        display: flex;
        flex-direction: column;
        border: none;
        border-bottom: 1px dashed black;
    }

    .countscontainer2 {
        font-size: 0.6rem;
        display: flex;
        flex-direction: column;
        border: none;
        border-bottom: 1px dashed black;
    }

    .countscontainer3 {
        font-size: 0.6rem;
        display: flex;
        flex-direction: column;
        border: none;
    }

    .countscontainer1 h1 {
        font-size: 1rem;
        padding: 10px;
    }

    .countscontainer2 h1 {
        font-size: 1rem;
        padding: 10px;
    }

    .countscontainer3 h1 {
        font-size: 1rem;
        padding: 10px;
    }

}