@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

.servicecomp {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 5%;
}

.faqheading {
    font-size: 2rem;
    display: flex;
    font-family: 'Playfair Display', serif;
    color: #5B2815;
    justify-content: center;
    align-items: center;
}

.faqheadingd {
    font-size: 3.5rem;
    display: flex;
    font-family: 'Playfair Display', serif;
    padding-bottom: 5%;
    color: #3b1b0f;
    justify-content: center;
    align-items: center;
}

.titleheading {
    font-family: 'Josefin Sans', sans-serif;
    color: #805127;
}

.Spancon {
    font-weight: 900;
    font-size: 1rem;
    color: #1d1c1c;


}

.scheading {
    height: 50vh;
    background-image: url(../images/bs2.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    background-color: #724934;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scheading img {
    margin-top: 0;
    filter: invert(100%);
}

.servicename {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 20px;

}

.scheading h1 {
    font-size: 3.8rem;
    text-transform: uppercase;
    font-family: 'Playfair Display', serif;

}

.scheading p {
    margin-top: 1%;
    font-size: 2rem;
    font-family: 'Josefin Sans', sans-serif;
    color: #fffefe61;
    background: -webkit-gradient(linear, left top, right top, from(#e6e6e6), to(#1d1c1c), color-stop(0.8, #ffffff)) no-repeat;
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.8, #fff)) no-repeat;
    background-size: 11px 100%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: flick 2.5s infinite ease-in-out;
}

@keyframes flick {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}

@media (max-width:1440px) {
    .scheading img {
        display: flex;
    }
}

@media (max-width:425px) {
    .scheading h1 {
        font-size: 2rem;
    }

    .scheading p {
        font-size: 1rem;
    }
    .scheading{
        height: max-content;
    }
    .titleheading{
        font-size: 1.2rem;
    }
    .faqheading{
        font-size: 1.5rem;
    }
    .faqheadingd{
        font-size: 2rem;
        text-align: center;
    }
}
