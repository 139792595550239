.bookbg{
    background-color: #F4F3F1 ;
}
.bor{
    background-color: #644222;
    height: 2vh;
}
.bookwrap{
    max-width: 1000px;
    text-align: center;
    margin: 0 auto;
}


.bookcontent h1{
    font-size: 2.9rem;
    color: #775D4D;
    font-family: 'Playfair Display', serif;
padding-top: 5%;
    margin-bottom: 5%;

}
.bookcontent p{
    font-size: 1.5rem;
}

@media (max-width:768px){
    .bookcontent h1{
        font-size: 2rem;
    }
    .bookcontent p{
        font-size: 1rem;
    }
    .bookcontent{
        padding: 20px;
    }
}