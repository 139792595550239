.locationhcolor{
    background-color: blanchedalmond;
}
.locationwrap{
    padding: 50px;
}
.locationstrip{
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background-color: burlywood;
}
.locationstrip span{
    font-weight: 900;
}
.locationwrap span, .locationwrap h1{
    text-align: center;
    font-size: 2.3rem;
    font-family: 'Playfair Display', serif;
}
.locationabout img {
    max-width: 20%;
    height: auto;
    border-radius: 0px 50px 50px 50px;
    display: block;
    /* Ensures that the image is treated as a block element */
    margin: 0 auto;
}

.wrapb{
    max-width: 1440px;
    margin: 0 auto;
}

.beforegrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    
}
.imag{
    margin: 0 auto;
}
.imag img{
    height: 350px;
    width: 400px;
    border: 5px solid burlywood;
    margin: 20px;
    margin-left: 2px;
    margin-right: 5px;
    border-radius: 2rem;
}

.explore{
    font-family: 'Playfair Display', serif;
    margin-top: 90px;
}


.servicescontent p{
    font-weight: 900;
}


.afterheadings{
    margin-top: 5%;
    text-align: center;
}


.theservices{
    font-family: 'Playfair Display', serif;
    font-size: 4rem;
}
.servicesdes{
    font-family: 'Playfair Display', serif;
}

.loc{
    margin-top: 10%;
    margin-bottom: 5%;
    color: #644222;
}

.pbg{
    padding-top: 20px;
    padding-bottom: 20px;
    
}
.pgh1{
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 50px;
}

.pgh1 h1{
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 4rem;
    color: #644222;
}

.pgp{
    text-align: center;
    font-size: 1.4rem;
    font-weight: 900;

}

.pcardwrap{
    max-width: 1200px;
    margin: 0 auto;
}
.pgrid{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.pcard{
    width: 500px;
    background-color: antiquewhite;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 3vh;
    margin: 0 auto;
    border: 3px solid #644222;

}

.pcard h1{
    text-align: center;
    font-family: 'Playfair Display', serif;

}

.pcard img{
    width: 300px;
    padding: 10px;
    border-radius: 3vh;
}

.pul {
    padding-top: 1%;
    padding-left: 10px;
    font-weight: 600;
}

.pli {
    padding-left: 32px;
    line-height: 1.4;
    margin-top: 20px;
    font-size: large;
}
.pcard1wrap{
    margin-top: 20px;
}
.pcard1{
    background-color: antiquewhite;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 3vh;
    border: 3px solid #644222;

}

.pcard1 h1{
    text-align: center;
    font-family: 'Playfair Display', serif;

}

.pcard1 img{
    width: 300px;
    padding: 5px;
}






@media (max-width:768px){
    .beforegrid{
        grid-template-columns: 1fr;
    }
   
    .imag img{
        height: 280px;
        width: 350px;
    }
    .servicescontent{
        padding: 5px;
    }
    .theservices{
        font-family: 'Playfair Display', serif;
        font-size: 3rem;
    }
    .pgh1 h1{
        text-align: center;
        font-family: 'Playfair Display', serif;
        font-size: 2.3rem;
        color: #644222;
    }
    
    .pgh1 p{
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        color: #644222;
        font-weight: 900;
    }


    .pgrid{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .pcard{
        width: 300px;
        background-color: antiquewhite;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-radius: 3vh;
        margin: 0 auto;
        border: 3px solid #644222;
    
    }
    .pcard1{
        background-color: antiquewhite;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        border-radius: 3vh;
        border: 3px solid #644222;
        width: 300px;
    
    }
}

@media (max-width:360px){
    .beforegrid{
        grid-template-columns: 1fr;
    }
    .imag img{
        height: 280px;
        width: 330px;
    }
}
@media (max-width:330px){
    .beforegrid{
        grid-template-columns: 1fr;
    }
    .imag img{
        height: 280px;
        width: 310px;
    }
}
@media (max-width:320px){
    .beforegrid{
        grid-template-columns: 1fr;
    }
    .imag img{
        height: 280px;
        width: 300px;
    }
}