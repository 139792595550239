.homecontainer {
    /* height: 55vh; */
    /* padding-bottom: 20px; */
    /* width: 100%; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* padding: 20px; */
    /* animation: change 20s infinite ease-in-out; */
    /* background: url('../images/Frame24.png') center/cover no-repeat; */
    /* background-size: 50%; */
    /* background-color: #0D0F14; */
}
.homenimagecontainer{
    display: flex;
    justify-content: center;
    max-height: 60vh;
    overflow: hidden;
    background-color: #0D0F14; 
    padding-top: 2%;
}
.homenimagecontainer img{

        object-fit: contain;
 
        /* object-fit: cover;  */

}

.homeh h1 {
    padding-top: 5%;
    font-size: 10rem;
    text-align: center;
    color: transparent;
    -webkit-text-stroke: 2px #eee;
    background: url(../images/back.webp);
    -webkit-background-clip: text;
    background-position: 0 0;
    animation: back 20s linear infinite;
    margin-left: 2%;
    margin-right: 2%;
    line-height: 10rem;
    width: 100%;
}

@keyframes back {
    100% {
        background-position: 2000px, 0;
    }
}

/* @keyframes change{
    
    
    0%{
      background: url('../images/Frame24.png') center center/cover no-repeat;
    }
} */



.column {
    width: 50%;
    float: left;

}

.columncontentback {
    background-color: #b5b5b5ab;
    text-align: center;
    padding: 5px;
    border: 2px dashed #3c240f;
    border-radius: 8px;
}

.columncontent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
}

.columncontent h1 {
    font-size: 3rem;
    color: #3c240f;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;

}

.columncontentback h2 {
    font-size: 4rem;
    font-family: 'Josefin Sans', sans-serif;
    color: #2c1a0a;

}

.homebut {
    margin-bottom: 20px;
    align-items: center;
}

.homeoptions {
    padding: 5px;
    background-color: #ffffffe0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.specialisedservices {
    color: #eee;
    background-color: #0D0F14;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap; */
    /* overflow: scroll; */
    gap: 20px;
    margin: 0 2%;
}
.specialisedserviceswrap{
    background-color: #0D0F14;
    padding-top: 1%;
    padding-bottom: 1%;

}

.homecardz {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 2px;
}

.homecardz h2{
    font-size: 1rem;
    font-family: 'Playfair Display', serif;
}

.homecardz img {
    height: 2.1rem;
    width: 2.1rem;
    filter: contrast(100%);
    background-color: #FFE5CC;
    border-radius: 100px;
    padding: 2px;
}

@media (max-width:768px){
    .specialisedservices {
        color: #eee;
        background-color: #0D0F14;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        /* overflow: scroll; */
        gap: 20px;
        margin: 0 2%;
    }

}
@media (max-width:375px){
    .specialisedservices {
        color: #eee;
        background-color: #0D0F14;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        /* overflow: scroll; */
        gap: 20px;
        margin: 0 2%;
    }

    .specialisedserviceswrap1{
        display: flex;
        justify-content: center;
        background-color: #0D0F14;
    }

}


@media (max-width:768px) {
    .homeh h1 {
        font-size: 4.3rem;
        line-height: 5rem;

    }

    .columncontent h1 {
        font-size: 1.2rem;
        color: #3c240f;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 900;
    }

    .columncontentback h2 {
        font-size: 2rem;
        font-family: 'Josefin Sans', sans-serif;
        color: #2c1a0a;
    }

    .columncontentback {
        background-color: #b5b5b5ab;
        text-align: center;
        margin-top: 41%;
        padding: 5px;
        border: 2px dashed #3c240f;
        border-radius: 8px;
    }

}




.homencontainer {
    /* display: flex; */
    height: max-content;
    /* width: 100%; */
    background-color: #0D0F14;
}

.homeng1 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}