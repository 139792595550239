.contacbanner{
    height: max-content;
    color: white;
    padding-left: 20%;
    padding-top: 5%;
    background-color: #664222;
    
}

.contacbanner h1{
    font-family: 'Playfair Display', serif;
    font-size: 4rem;
}

.contacbanner p{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;
    padding-bottom: 7%;
}

@media (max-width:768px){
    .contacbanner h1{
        font-size: 3rem;
        text-align: center;
    }
    
    .contacbanner p{
        font-family: 'Josefin Sans', sans-serif;
        font-size: 1rem;
        padding-bottom: 7%;
        text-align: center;
    }

    .contacbanner{
        padding-left: 0;
    }
}