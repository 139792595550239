.dentallocationoffers {
    height: max-content;
    padding: 10px;
    border: 5px dashed #644222;
    background-color: blanchedalmond;
    max-width: 1000px;
    margin: 32px auto;
    text-align: center;
}

.dentallocaboutgrid {
    display: flex;
}

.dentallocbancol {
    background-color: rgb(238, 205, 163);
    color: #644222;
}

.dentallocban {
    display: grid;
    grid-template-columns: 1fr 0.3fr 0.3fr;
    max-width: 1440px;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    padding: 50px;
    margin-top: 50px;
    align-items: center;
}

.dentallocban h1 {
    padding-right: 100px;
    font-family: 'Playfair Display', serif;
    text-align: center;
    /* Center-align the text */
}

.dentallocbgwrap {
    margin: 0 auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 5;
    background-image: url('../images/sp.webp');
    /* Replace 'your-image-url.jpg' with the actual image URL */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #9e6f44;
    background-blend-mode: multiply;
    color: white;

}

.dentallocbgwrap1 {
    margin: 0 auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 5;
    background-image: url('../images/veneers1.webp');
    /* Replace 'your-image-url.jpg' with the actual image URL */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #9e6f44;
    background-blend-mode: multiply;
    color: white;

}


.dentallocbgwrap h1 {
    color: white;
}

.dentallocbgwrap1 h1 {
    color: white;
}

@media (max-width: 768px) {
    .dentallocban {
        grid-template-columns: 1fr;
    }

    .dentallocban h1 {
        font-size: 1.4rem;
        padding: 0;
    }
}